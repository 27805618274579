import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"

class columnAlternating extends React.Component {
  render() {
    return (
      <MDBRow className={`about-item align-items-center ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        <MDBCol lg="6">
          <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded" />
        </MDBCol>
        <MDBCol lg="6">
          <div className="about-content">
            <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">
              {this.props.title}
            </h3>
            <div
              className="mt-3 font-w-400 text-medium"
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default columnAlternating