import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker } from '@react-google-maps/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faGlobe, faFax, faMapLocationDot } from '@fortawesome/pro-solid-svg-icons'

class CardAddressMap extends React.Component {
  render() {
    return (
      <MDBContainer>
        <MDBRow className="real-estate-address">
          <MDBCol lg="4" md="5" className="my-auto">
            
            <p className="font-w-400 text-large" style={{ whiteSpace: 'pre-wrap' }} >{this.props.address} </p>

            <ul className="fa-ul ml-0 pt-4">
             {(this.props.tel || this.props.fax) && (
             <>
                <li>
                    <span className="fa-li position-static">
                    <FontAwesomeIcon icon={faPhoneAlt} className="mr-3" />{this.props.tel}
                    </span>
                </li>
                <li>
                    <span className="fa-li position-static">
                    <FontAwesomeIcon icon={faFax} className="mr-3" />{this.props.fax}
                    </span>
                </li>
             </>
             )}
                <li>
                    <span className="fa-li position-static">
                    <FontAwesomeIcon icon={faMapLocationDot} className="mr-3" /><a className="effect" rel="noopener" href={'https://www.google.com/maps/dir/Current+Location/' + this.props.lat + ',' + this.props.lng} target="_blank">Google Directions</a>
                    </span>
                </li>
              {this.props.url && (
              <>
                <li>
                    <span className="fa-li position-static">
                    <FontAwesomeIcon icon={faGlobe} className="mr-3" /><a href={this.props.url} className="effect" rel="noopener">{this.props.url}</a>
                    </span>
                </li>
              </>
              )}
           </ul>  
         </MDBCol>

          <MDBCol lg="8" md="7">
            <div id="map-container" className="rounded z-depth-1-half map-container mt-5 mt-lg-0 mt-md-0" style={{ height: '400px' }}>
              <LoadScript id="script-loader" googleMapsApiKey="AIzaSyCpXjW8NbD3JZOylLfE3vYEBj1spgmovhU">
                <GoogleMap id="example-map" mapContainerStyle={{ height: '400px', width: '100%', }} zoom={15} center={{ lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng), }}>
                  <Marker
                    onLoad={marker => {}}
                    position={{
                      lat: parseFloat(this.props.lat),
                      lng: parseFloat(this.props.lng),
                      title: 'UNICOM Global HQ',
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default CardAddressMap
