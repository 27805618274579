import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import ColumnAlternating from '../components/columnAlternating'
import CardAddressMap from '../components/cardAddressMap'

const RealEstateTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page"> 
    <Layout>
        <Hero
          class="intro-65"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}              
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section &&
          <section className="bg-light-blue">
            <MDBContainer>
              <div className="about-overview">
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <ColumnAlternating
                      key={index}
                      title={sections.title}
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }

        {post.frontmatter.address && 
          <section className="bg-gray-light">
            <CardAddressMap
              address={post.frontmatter.address}
              tel={post.frontmatter.telephone}
              fax={post.frontmatter.fax}
              url={post.frontmatter.url}
              lat={post.frontmatter.lat}
              lng={post.frontmatter.lng}
            />
          </section>
        }
      </Layout>
      </div>
  )
}
export default RealEstateTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "real-estate" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        address
        telephone
        fax
        url
        lat
        lng
        openingpara
        alttext
        ctatext
        ctaslug          
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle
          description
          image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
            }
          }
          placement
          alttext
        }
      }
      html
    }
  }
`
